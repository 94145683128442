.refreshButton:hover {
  background-color: #76b339; /* Slightly lighter or darker than #8dc346 */
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2); /* Optional: Adds depth */
}

.refreshButton {
  transition: background-color 0.3s, box-shadow 0.3s;
}

.instructionsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;
}

.instructionsBox > div {
  width: 100%;
}

.greenBox {
  position: relative;
  margin-bottom: 0px;
  background-color: #8dc346;
  border-radius: 3px;
  padding: 10px;
  color: white;
  z-index: 1010; /* Ensure greenBox is always on top of expandBox when needed */
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.greenBox:hover .dropdownContent {
  display: block;
}

.greenBox.active {
  background-color: white;
  border: 1px solid #8dc346;
}

.greenBox .greenBoxText {
  font-size: 0.9em;
}

.expandButton {
  color: white;
  font-size: 0.4rem;
}

/* Box with light grey border that is connected to instruction box */
.expandBox {
  position: relative;
  background-color: white;
  padding: 0;
  border: 1px solid lightgrey;
  border-radius: 4px;
  width: 98%;
  margin: 0 auto;
  margin-top: -10px;
  padding-top: 3%;
  padding-bottom: 1%;
  z-index: 1000; /* Increased to ensure it is above other elements */
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* divider that holds the image and the caption */
.imgContainer {
  padding-top: 5px;
  max-width: 200px;
  flex: 0 0 calc(50% - 20px);
  width: 100%;
  margin: auto;
}

.expandBox .itrackCaption {
  color: grey;
  font-size: 0.5rem;
  font-style: italic;
  margin-top: 1px;
  text-align: center;
  display: block; /* Ensures each caption is on its own line */
}

.itrackImage {
  width: 100%;
}

.whiteBox {
  width: 80%;
  height: 80%;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Installer details */

.submissionTableCell {
  padding: 2px 4px !important;
  font-size: 0.6rem !important;
  line-height: 1 !important;
}
