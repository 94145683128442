.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.5)
    ),
    /* Translucent white layer */ url("media/background.png"); /* Your original background image */
  background-size: cover;
  background-position: center;
  position: relative;
}

.form_control {
  display: flex;
  flex-direction: column;
  align-items: center;
}
