/* Global Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  height: 100%; /* Added to ensure full height */
}
/* LoginPage Styles */

.login-form {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ffff;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #8dc346;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #688b39;
}

.logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between logos */
  margin-bottom: 20px;
}

.login-logo {
  display: block;
  max-width: 140px; /* Adjust as needed */
  height: auto;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333; /* Adjust the color as needed */
}

.form-extras {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-extras label {
  cursor: pointer;
}

.form-extras a {
  color: #007bff; /* Adjust link color as needed */
  text-decoration: none;
}

.form-extras a:hover {
  text-decoration: underline;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #ffffff; /* Example background color */
  border: 1px solid #ddd; /* Example border */
  min-width: 160px; /* Adjust as needed */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /* Optional: for dropdown shadow */
}

.dropdown-menu div {
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  color: black; /* Text color */
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #ffffff; /* Color change on hover */
  font-weight: bolder;
}

.dashboard {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard-prices-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding in the box's dimensions */
}
.dashboard-container {
  position: relative;
  padding-top: 1px; /* Reduced top padding */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center align the welcome message */
}

.dashboard-container > h1 {
  color: #111;
  /* margin-bottom: 10px; */
}

.dashboard-content {
  /* overflow-y: auto; Enables vertical scrolling */
  display: flex;
  width: 90%;

  min-height: 120vh;
  background: white;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  opacity: 1; /* Adjust for desired faded effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Add some space between the welcome message and content box */
}

.content {
  max-width: 90%;
  width: 100%;
  height: 90vh;
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  opacity: 1; /* Adjust for desired faded effect */
  margin-top: 20px; /* Add some space between the welcome message and content box */
}

.sites-list {
  width: 100%; /* Make sure it takes the full width of its container */
  display: flex;
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center items horizontally */
  gap: 20px;
}
.left-pane {
  width: 30%; /* Full width of its container */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: auto; /* Adjust the height as needed, vh unit ensures it's relative to the viewport height */
  /* Add more styling as needed */
}
.right-pane {
  width: 70%;

  /* Add more styling as needed */
}
.site-item {
  width: 100%;
  max-width: 430px;
  /* border: 1px solid #ccc; */
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin: 10px;
}

.site-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.site-item h3 {
  margin: 0;
  color: #8dc346; /* Adjust color as needed */
}

.site-item p {
  margin: 5px 0;
}

.navbar {
  background-color: #8dc346;
  /* padding: 10px 20px; */
  height: 3.5vh;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar button {
  background-color: #8dc346;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.navbar button:hover {
  background-color: #516e29;
}

.login-logoo {
  display: block;
  max-width: 250px; /* Adjust as needed */
  height: auto;
}

.table-container {
  margin: 20px auto; /* Center the table container horizontally */
  width: 80%; /* Set the width of the container */
}

.table-container h2 {
  text-align: center; /* Center the heading */
  margin-bottom: 10px;
}

table {
  width: 80%; /* Make the table take full width of its container */
  margin: 0 auto; /* Center the table */
  border-collapse: collapse; /* Optional: for better table cell border handling */
}
.green-cell,
.light-cell {
  padding: 10px; /* Add padding for better readability */
  text-align: center; /* Align text to center */
}

.green-cell {
  background-color: #8dc346;
  color: white; /* Set text color to white */

  /* Adjust the width if needed */
}

.light-cell {
  background-color: #e9f8cb;
  padding: 5px; /* Reduced padding for light green cells */
  width: 20%; /* Smaller width for light green cells */
}

.fancy-download-btn {
  background-color: #8dc346; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Top & Bottom, Left & Right padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); /* Text shadow for depth */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Box shadow for 3D effect */
  margin-top: 20px; /* Margin at the top */
}

.fancy-download-btn:hover {
  background-color: #367c39; /* Darker green on hover */
  color: #ddd; /* Light text on hover */
}

.fancy-search-input {
  width: 80%;
  padding: 10px 15px;
  margin: 10px 0;
  border: 2px solid #8dc346; /* Blue border */
  border-radius: 25px; /* Rounded borders */
  outline: none; /* Removes the default focus outline */
  font-size: 16px; /* Larger font size */
  transition: border-color 0.3s;
}

.fancy-search-input:focus {
  border-color: #8dc346; /* Darker blue when focused */
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.5); /* Adds a light blue glow */
}

.fancy-search-input::placeholder {
  color: #999; /* Lighter text for the placeholder */
}

.search-container {
  display: flex;
  justify-content: center; /* Centers the search bar */
  padding: 20px;
}
