.signal-bar {
  width: 8px;
  background-color: #ccc;
  transition: background-color 0.25s ease;
  flex-grow: 1;
}

.signal-bar-active {
  background-color: #000000;
}

.signal-bar-container {
  display: flex;
  height: 30px;
  gap: 3px;
}